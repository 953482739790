import {CdkStepper, StepperSelectionEvent} from '@angular/cdk/stepper';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    OnDestroy,
    Output,
    QueryList
} from '@angular/core';
import {Subscription} from 'rxjs';
import {WizardStepComponent} from '../wizard-step/wizard-step.component';


@Component({
    selector: 'multi-step-wizard',
    template: '',
    standalone: true,
})
export class MultiStepWizardComponent extends CdkStepper implements AfterViewInit, OnDestroy {

    @Output() stepChanged: EventEmitter<string> = new EventEmitter<string>();

    @ContentChildren(WizardStepComponent, {descendants: true}) override _steps!: QueryList<WizardStepComponent>;

    override readonly steps: QueryList<WizardStepComponent> = new QueryList<WizardStepComponent>();

    stepsCount: number | undefined;
    eventListeners: Subscription[] | undefined;

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.setStepsCount();
        this.setEventListeners();
    }

    ngAfterContentInit() {
        super.ngAfterContentInit();
    }

    setStepsCount = () => {
        const lastStepIsConclusion = this.steps && this.steps.last.conclusion;
        this.stepsCount = lastStepIsConclusion ? this.steps!.length - 1 : this.steps!.length;
    }

    setEventListeners = () => {
        this.eventListeners = [
            this.selectionChange.subscribe(this.onStepChanged),
        ];
        this.steps.toArray()
            .forEach(step =>
                this.eventListeners!.push(
                    step.completed$.subscribe(this.onStepCompleted)
                )
            );
    }

    onStepCompleted = (step: WizardStepComponent) => {
        if (step !== null && step.autoMove) {
            this.next();
        }
    }


    override next = () => {
        (this.selected as WizardStepComponent).onCompletion.emit();
        super.next();
    }

    onStepChanged = (event: StepperSelectionEvent) => {
        if (event) {
            const {selectedStep} = event;
            this.stepChanged.emit((selectedStep as WizardStepComponent).name);
        }
    }

    /**
     * Used by steps indexer (1,2,3,4) to change selected step
     */
    setSelectedStep = (index: number) => {
        const stepsArray = this.steps!.toArray();
        const previousStep = stepsArray[Math.max(0, index - 1)];
        if ((previousStep as WizardStepComponent).isValid()) {
            this.selectedIndex = index;
        }
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.eventListeners!.forEach(listener => listener.unsubscribe());
    }

}
