import {CdkStep} from '@angular/cdk/stepper';
import {EventEmitter, OnDestroy} from '@angular/core';
import {Component, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'wizard-step',
    templateUrl: './wizard-step.component.html',
    standalone: true,
})
export class WizardStepComponent extends CdkStep implements OnInit, OnDestroy {

    /**
     * The name of the step
     */
    @Input() name: string;

    /**
     * Whether this is the conclusion/summary step
     * If the value is true, then the wizard will hide the step controls.
     */
    @Input() conclusion: boolean;

    /**
     * Whether to move to the next step once this step is completed.
     * The step is considered as "completed" once the step control status is valid
     * @default false
     */
    @Input() autoMove: boolean = false;

    /**
     * inside sub step show that step has some sub steps that should be treated before move to next or prev step
     * logic that count sub steps implements inside component that holds wizard and steps
     */
    @Input() hasNextSubStep: boolean = false;
    @Input() hasPrevSubStep: boolean = false;
    @Output() onSubStepChange: EventEmitter<'next' | 'prev'> = new EventEmitter<'next' | 'prev'>();

    /**
     * Emits when the step is completed
     */
    @Output() onCompletion: EventEmitter<void> = new EventEmitter<void>();

    private _completed: BehaviorSubject<WizardStepComponent> = new BehaviorSubject<WizardStepComponent>(null);
    readonly completed$: Observable<WizardStepComponent> = this._completed.asObservable();

    unsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.setStepControlValueChangeListener();
    }

    setStepControlValueChangeListener = () => {
        if (this.stepControl) {
            this.stepControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(this.onControlValueChange);
        }
    }

    onControlValueChange = (value: any) => {
        if (this.stepControl.valid && this.autoMove) {
            this._completed.next(this);
        }
    }

    isValid = (): boolean => {
        return this.stepControl ? this.stepControl.valid : true;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

}
